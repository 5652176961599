import { link, tokenLink, dnsPortal, urlADM } from "../../services/variables";

//alguns link possuem token diferente do padrão
const linksNavbar = [
  // Município
  {
    name: `Município`,
    top_list: true,
    img: "Municipio",
    sub_link: [
      {
        name: `A Cidade`,
        link: `/municipio/cidade`,
      },
      {
        name: `Distâncias`,
        link: `/municipio/distancias`,
      },
      {
        name: `História`,
        link: `/municipio/historia`,
      },
      {
        name: `População`,
        link: `/municipio/populacao`,
      },
      {
        name: `Mapa`,
        link: `/municipio/mapa`,
      },
      {
        name: `Endereços Úteis`,
        link: `/municipio/enderecos-uteis`,
      },
    ],
  },
  {
    name: `Informações Institucionais`,
    top_list: true,
    img: "Institucional",
    sub_link: [
      {
        name: `Registro das Competências`,
        link: `/informacoes-institucionais/registro-das-competencias`,
      },
      {
        name: `Estrutura Organizacional`,
        link: `/estrutura-organizacional`,
      },
      {
        name: `Leis Municipais / Estaduais`,
        sub_link: [
          {
            name: `Lei Orgânica`,
            link: `${urlADM}/admpublica/lei-organica?token=${tokenLink}`,
          },
          {
            name: `Código Tributário`,
            link: `${urlADM}/admpublica/codigo-tributario?token=${tokenLink}`,
          },
          {
            name: `Leis Municipais`,
            link: `${urlADM}/admpublica/lei?token=${tokenLink}`,
          },
          {
            name: `Leis Estaduais`,
            link: `${urlADM}/acesso-a-informacao?token=${tokenLink}`,
          },
          {
            name: `Decretos `,
            link: `${urlADM}/admpublica/decreto?token=${tokenLink}`,
          },
          {
            name: `Portarias`,
            link: `${urlADM}/admpublica/portarias?token=${tokenLink}`,
          },
          {
            name: `Outros Atos Administrativos `,
            link: `${urlADM}/admpublica/outros-atos-administrativos?token=${tokenLink}`,
          },
        ],
      },
      {
        name: "Concursos / Seletivos",
        link: `${urlADM}/licitacoes-e-contratos/concurso?token=${tokenLink}`,
      },
      {
        name: "Conselhos Municipais",
        link: `${urlADM}/admpublica/conselhos-municipais?token=${tokenLink}`,
      },
      {
        name: `Perguntas e Respostas`,
        link: `${link}/esic/perguntaresposta.xhtml?token=${tokenLink}&tipo=Perguntas%20e%20Respostas%20e-Sic`,
      },
      {
        name: `Fale Conosco`,
        link: `/atendimento/fale-conosco-atendimento`,
      },
    ],
  },
  {
    name: `Diário Oficial`,
    top_list: true,
    img: "Diario_OFI",
    sub_link: [
      {
        name: `Diário Oficial Eletrônico`,
        link: `${urlADM}/diario-oficial?token=${tokenLink}`,
      },
      {
        name: `Lei de criação do Diário`,
        link: `${urlADM}/admpublica/lei-de-criacao-do-diario-oficial-eletronico-do-municipio?token=${tokenLink}`,
      },
      {
        name: `Ato de nomeação do responsável`,
        link: `${urlADM}/admpublica/ato-de-nomeacao-de-pessoa-responsavel-pelas-publicacoes-do-diario-oficial-do-municipio?token=${tokenLink}`,
      },
    ],
  },
  {
    name: `Pessoal`,
    top_list: true,
    img: "Pessoal",
    sub_link: [
      {
        name: `Contracheque`,
        link: `https://bomjesusdasselvas.rhfacil.portalpublico.net/Auth/auth-login?ReturnUrl=%2F`,
      },
      {
        name: `Estatuto do Servidor`,
        link: `${urlADM}/admpublica/estatuto-dos-servidores?token=${tokenLink}`,
      },
      {
        name: `Folha de pagamento`,
        // link: `#`,
        sub_link: [
          {
            name: `Remuneração dos Servidores`,
            link: `http://www.transparenciadministrativa.com.br/inforfolha/contracheque/remuneracao.xhtml?token=f1f6507e-b86c-4844-a024-741eb187d309`,
          },
          {
            name: `Gestões Anteriores`,
            link: `http://www.transparenciadministrativa.com.br/portaltm/contracheque/remuneracao.xhtml?token=1ffcea7007fcd2e4635e427841f4914f42375691`,
          },
        ],
      },
      {
        name: `Relação Nominal de cada servidor`,
        link: `${urlADM}/admpublica/relacao-nominal-de-cada-servidor?token=${tokenLink}`,
      },
      {
        name: `Servidores inativos`,
        link: `${urlADM}/admpublica/relacao-de-servidores-inativos?token=${tokenLink}`,
      },
      {
        name: `Servidores ocupante de cargo em comissão`,
        link: `${urlADM}/admpublica/relacao-de-servidores-ocupantes-de-cargo-em-comissao?token=${tokenLink}`,
      },
      {
        name: `Servidores cedidos para outros Órgãos Públicos`,
        link: `${urlADM}/admpublica/relacao-de-servidores-cedidos-para-outros-orgaos-publicos?token=${tokenLink}`,
      },
      {
        name: `Relação nominal dos estagiários`,
        link: `${urlADM}/admpublica/relacao-nominal-dos-estagiarios?token=${tokenLink}`,
      },
      {
        name: `Relação de contratados temporariamente e contratos de terceirização`,
        link: `${urlADM}/admpublica/relacao-nominal-dos-agentes-publicos-contratados-temporariamente-e-contratados-de-terceirizacao?token=${tokenLink}`,
      },
      {
        name: `Tabela com o padrão remuneratório dos cargos e função`,
        link: `${urlADM}/admpublica/tabela-remuneratoria-dos-cargos-e-funcoes?token=${tokenLink}`,
      },
    ],
  },
  // Diárias
  {
    name: `Diárias`,
    top_list: true,
    img: "Diárias",
    sub_link: [
      {
        name: `Relação das Diárias`,
        link: `${urlADM}/admpublica/relacao-das-diarias-dentro-e-fora-estado-e-fora-do-pais?token=${tokenLink}`,
      },
      {
        name: `Diárias e Passagens`,
        link: `https://portalcontreina.dcfiorilli.com.br:882/Transparencia/Default.aspx?AcessoIndividual=lnkDiarias`,
      },
      {
        name: `Legislação`,
        link: `${urlADM}/admpublica/diarias-e-legislacao?token=${tokenLink}`,
      },
    ],
  },
  // Transparência
  {
    name: `Transparência`,
    link: `/transparencia`,
    top_list: true,
    img: "Transparência",
  },
  // Licitações e Contratos
  {
    name: `Licitações e Contratos`,
    top_list: true,
    img: "licitaçoes",
    sub_link: [
      {
        name: `Plano Anual de Contratações`,
        link: `${urlADM}/admpublica/plano-anual-de-contratacoes?token=${tokenLink}`,
      },
      {
        name: `Indicação do Fiscal de Contrato`,
        link: `/licitacoes-e-contratos/indicacao-do-fiscal-do-contrato`,
      },
      {
        name: `Avisos`,
        link: `/licitacoes-e-contratos/avisos`,
      },
      {
        name: `Edital`,
        link: `/licitacoes-e-contratos/edital`,
      },
      {
        name: `Dispensas`,
        link: `/licitacoes-e-contratos/dispensas`,
      },
      {
        name: `Inexigibilidade`,
        link: `/licitacoes-e-contratos/inexigibilidade`,
      },
      {
        name: `Pregão Eletrônico`,
        link: `/licitacoes-e-contratos/pregao-eletronico`,
      },
      {
        name: `Adesão de Ata - SRP `,
        link: `/licitacoes-e-contratos/ata-de-adesao`,
      },
      {
        name: `Resultados do Certame`,
        link: `/licitacoes-e-contratos/resultado-do-certame`,
      },
      {
        name: `Desertas e Fracassadas`,
        link: `/licitacoes-e-contratos/desertas-fracassadas`,
      },
      {
        name: `Obras Públicas`,
        link: `/licitacoes-e-contratos/obras-publicas`,
      },
      {
        name: `Termo de Aditivo`,
        link: `/licitacoes-e-contratos/termo-de-aditivo`,
      },
      {
        name: `Contratos `,
        link: `/licitacoes-e-contratos/contrato`,
      },
      {
        name: `Fornecedores Sancionados`,
        link: `${urlADM}/licitacoes-e-contratos/fornecedores-sancionados?token=${tokenLink}`,
      },
    ],
  },
  // Ouvidoria
  {
    name: `Ouvidoria`,
    top_list: true,
    img: "Ouvidoria",
    sub_link: [
      {
        name: `Ato de Nomeação - Ouvidor`,
        link: `${urlADM}/admpublica/divisao/regulamentacao-da-ouvidoria?token=${tokenLink}`,
      },
      {
        name: `Atendimento Presencial`,
        link: `/ouvidoria/atendimento-presencial`,
      },
      {
        name: `Redes Sociais`,
        link: `/redes-sociais`,
      },
      {
        name: `Carta de Serviços ao Usuário`,
        link: `https://administracaopublica.com.br/downloads?n=01612668000152&a=atos_administrativos_3fffa17e-404a-4712-bca8-c27cbbc9857d.pdf`,
      },
      {
        name: `Canal Eletrônico de Acesso`,
        link: `${urlADM}/ouvidoria?token=${tokenLink}`,
      },
      {
        name: `Perguntas e Respostas`,
        link: `${urlADM}/ouvidoria/acompanhamento?token=${tokenLink}`,
      },
    ],
  },
  // Cidadão | e-SIC
  {
    name: `Cidadão | e-SIC `,
    top_list: true,
    img: "Cidadão",
    sub_link: [
      {
        name: `e-SIC - Regulamentação`,
        link: `${urlADM}/esic?token=${tokenLink}`,
      },
      {
        name: `e-SIC - Perguntas e Respostas`,
        link: `${urlADM}/e-sic/perguntas-e-respostas?token=${tokenLink}`,
      },
      {
        name: `e-SIC`,
        link: `${urlADM}/e-sic?token=${tokenLink}`,
      },
      {
        name: `Portaria do responsável pelo e-SIC`,
        link: `${urlADM}/admpublica/divisao/regulamentacao-esic?token=${tokenLink}`,
      },
      {
        name: `Instrumento normativo local que regulamente a LAI`,
        link: `${urlADM}/acesso-a-informacao?token=${tokenLink}`,
      },
      {
        name: `e-SIC - Rol de Informações (12 meses)`,
        link: `/cidadao-e-sic/e-sic-rol-de-informacoes(12meses)`,
      },
      {
        name: `e-SIC - Rol de documentos (Grau Sigilo)`,
        link: `/cidadao-e-sic/e-sic-rol-de-documentos(12meses)`,
      },
    ],
  },
  {
    name: `Tributos`,
    top_list: true,
    img: "Contribuinte",
    sub_link: [
      {
        name: `Nota Fiscal Eletrônica`,
        link: `https://bomjesus.portalpublico.net/`,
      },
    ],
  },
  {
    name: `BOMPREV`,
    top_list: true,
    img: "Contribuinte",
    link: `https://app.tcema.tc.br/epca/mural`,
  },
  // {
  //   name: `Sala do Empreendedor`,
  //   top_list: true,
  //   img: "Pessoal",
  //   link: `/transparencia/sala-empreendedor`,
  // },
  {
    name: `LGPD`,
    top_list: true,
    img: "lgpd",
    link: `${urlADM}/lgpd?token=${tokenLink}`,
  },
  // Usuário
  {
    name: `Usuário`,
    top_list: true,
    img: "admin-navbar",
    sub_link: [
      {
        name: `Login - Portal`,
        link: `https://noticias.administracaopublica.com.br/auth/login`,
      },
      {
        name: `Login - Diário`,
        link: `${link}/diario/login.xhtml`,
      },
      {
        name: `Login - Licitação`,
        link: `${link}/licitacaotm/login.xhtml`,
      },
      {
        name: `Login - Atendimento`,
        link: `https://atendimento.administracaopublica.com.br/auth/login`,
      },
      {
        name: `WebMail`,
        link: `https://webmail-seguro.com.br/${dnsPortal}`,
      },
    ],
  },
];
export default linksNavbar;
