import api from "../../../../services/api";
import Sacops from "../../../../components/jsons/routesTranparency/routesSacopAndLicitacoes";
import NavMenu from "../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../components/public/others/NavbarCM";
import Footer from "../../../../components/public/others/Footer";
import ListSacop from "../../../../components/public/transparency/concurso/ListFiles";
import Loading from "../../../../components/public/others/Loading";
import Search from "../../../../components/public/others/ordinances/Utils";
import { TipoPortal } from "../../../../services/variables";

import { useEffect, useState } from "react";

export default function NewsPage(props) {
  const SacopFilter = "Concursos Públicos";
  const [listResult, setListResult] = useState();
  const [type, setType] = useState();
  const [route, setRoute] = useState();
  const [removeLoading, setRemoveLoading] = useState(false);

  useEffect(() => {
    console.log(type);
    api
      .get(`/concurso/list/all`)
      .then((res) => {
        if (res.data.err !== true) {
          setListResult(res.data.res);
          // setRemoveLoading(true)
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
      {TipoPortal == 3 ? (
        <>
          <NavMenu />
        </>
      ) : (
        <>
          <NavMenuCM />
        </>
      )}
      <div className="section-title rotes_pages">
        <h6>Início {props.routes}</h6>
      </div>
      <div className="section-title-portarias">
        <h4>{SacopFilter}</h4>
      </div>
      {listResult != undefined ? (
        <>
          <ListSacop name={SacopFilter} data={listResult} />
        </>
      ) : (
        <>
          <Search />
          {!removeLoading && <Loading />}
        </>
      )}
      <Footer />
    </>
  );
}
