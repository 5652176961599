import Acts from "../../../components/jsons/routesTranparency/routesAtcsAdministrative";
import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import Footer from "../../../components/public/others/Footer";
import ShowDocuments from "../../../components/public/others/ordinances/ShowResult";
import { useParams } from "react-router-dom";
import { TipoPortal } from "../../../services/variables";

export default function NewsPage(props) {
  const ActsFilter = props.name;
  const secondaryRoute = props.route;

  const { search } = useParams();

  return (
    <>
      {TipoPortal == 3 ? (
        <>
          <NavMenu />
        </>
      ) : (
        <>
          <NavMenuCM />
        </>
      )}

      {Acts.map((i, index) => {
        if (i.TITLE == ActsFilter) {
          return (
            <div key={index}>
              <div className="section-title rotes_pages" key={index}>
                <h6>
                  {secondaryRoute != undefined ? (
                    `Início ${secondaryRoute} / ${i.TITLE}`
                  ) : (
                    <>{i.ROUTE}</>
                  )}
                </h6>
              </div>
              <div className="section-title-portarias">
                <h4>{i.TITLE}</h4>
              </div>
              <ShowDocuments name={i.TITLE} select={i.SELECT} />
            </div>
          );
        }
      })}
      {search != undefined && (
        <div>
          <div className="section-title rotes_pages">
            <h6>{search}</h6>
          </div>
          <div className="section-title-portarias">
            <h4>{search}</h4>
          </div>
          <ShowDocuments name={search} />
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}
